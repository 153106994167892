import { 
    REQUEST_LOGIN, 
    SUCCESS_LOGIN,
    FAILED_LOGIN,
    REQUEST_LOGOUT
 } from "../../../settings/constants/constants"
import { setAuthData } from "../../../utils/auth/auth"


const INIT_STATE = {
    token: null,
    user: null,
    loginStatus: "none",
    isRequested: false
}

export default (state = INIT_STATE, actions) => {
    switch(actions.type){
        case REQUEST_LOGIN:
            return{
                ...state,
                token: null,
                user: null,
                loginStatus: "none",
                isRequested: true
            }
        case SUCCESS_LOGIN:
            let t = actions.payload.token
            let r = actions.payload.userInfo
            //setAuthData(actions.payload.token, null)
            return{
                ...state,
                token: actions.payload.token,
                user: actions.payload.userInfo,
                loginStatus: "success",
                isRequested: false
            }
        case FAILED_LOGIN:
            return{
                ...state,
                token: null,
                user: null,
                loginStatus: "failed",
                isRequested: false
            }
        case REQUEST_LOGOUT:
            return{
                ...state,
                token:null,
                user: null
            }
        default:
            return state;
    }
}