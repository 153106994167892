import { postAsync } from "../../../utils/httpClient/HttpClient"
import { setAuthData } from "../../../utils/auth/auth";
import {
    REQUEST_LOGIN,
    SUCCESS_LOGIN,
    FAILED_LOGIN
} from "../../../settings/constants/constants"

import axios from "axios";

export const actionCreators = {
    requestLogin: (userName, password, isAdmin) => async(dispatch, getState) => {
        dispatch({type: "REQUEST_LOGIN"})

        console.log(process.env)
        let url = `${process.env.REACT_APP_API_URL}users/login`

        const data = {
            userName,
            password,
            isAdmin
        }
        try{
            let response = await postAsync(url, data)
            if(response){
                console.log(response)
                let res = response.data.body.data
                dispatch({type: SUCCESS_LOGIN, payload: res})
                setAuthData(res)
                console.log(res)
            }
        }catch(e){
            console.log(e)
            dispatch({type: FAILED_LOGIN})
                setAuthData({})
        }

        


        // fetch(url, {
        // method: 'POST', // or 'PUT'
        // headers: {
        //     'Content-Type': 'application/json',
        // },
        // body: JSON.stringify(data),
        // })
        // .then(response => response.json())
        // .then(data => {
        //     console.log('Success:', data.body && data.body.data);
        //     let userInfo = data.body && data.body.data && data.body.data.userInfo
        //     let token = data.body && data.body.data && data.body.data.token
        //     dispatch({type: SUCCESS_LOGIN, payload: {userInfo, token}})
            

        //     localStorage.setItem("token", token);

        // })
        // .catch((error) => {
        //     console.error('Error:', error);
        // });


        // axios.post(url, data)
        //   .then(function (response) {
        //     console.log(response);
        //   })
        //   .catch(function (error) {
        //     console.log(error);
        //   });

        // let response = postAsync(url, data)
        // .then(data =>{
        //     console.log(data)
        // }).catch((e) =>{
        //     console.log(e)
        // })
        // console.log(response)

        // axios.post(url, data,
        // {
        //     'Access-Control-Allow-Origin' : '*'
        // }).then(res=>{
        //     console.log(res.data)
        //     if(res.data.statusCode === 200){
        //         //this.setState({message:res.data.body.data})
        //         //router.push('/enterotp')
        //     }
        //     else this.setState({error : res.data.message})
        // }).catch(err=>{
        //     console.log(err)
        //     //this.setState({isOpen : true})
        //     // this.setState({signUpError : err.message})
        // })
            


    }
}