import {combineReducers} from "redux";
import authReducer from "../auth/authReducer";
import userReducer from "../user/userReducer";
import notificationReducer from "../notification/notificationReducer"

export default combineReducers({
    auth: authReducer,
    user: userReducer,
    notification: notificationReducer
})
    
