import { 
    REQUEST_GET_ALL_ADMIN_USERS,
    SUCCESS_GET_ALL_ADMIN_USERS,
    FAILED_GET_ALL_ADMIN_USERS,

    REQUEST_GET_ADMIN_USER,
    SUCCESS_GET_ADMIN_USER,
    FAILED_GET_ADMIN_USER,

    REQUEST_GET_USER_CAMPAIGNS,
    SUCCESS_GET_USER_CAMPAIGNS,
    FAILED_GET_USER_CAMPAIGNS,

    REQUEST_GET_ALL_WITHDRAWALS,
    SUCCESS_GET_ALL_WITHDRAWALS,
    FAILED_GET_ALL_WITHDRAWALS,

    REQUEST_GET_DONATION_BY_CAMPAIGN,
    SUCCESS_GET_DONATION_BY_CAMPAIGN,
    FAILED_GET_DONATION_BY_CAMPAIGN,
    REQUEST_GET_WITHDRAWAL_BY_CAMPAIGN,
    SUCCESS_GET_WITHDRAWAL_BY_CAMPAIGN,
    FAILED_GET_WITHDRAWAL_BY_CAMPAIGN
 } from "../../../settings/constants/constants"

const INIT_STATE = {
    isLoading: false,
    users:[],
    campaigns:[],
    donations:[],
    withdrawls: [],
    user: null
}

export default (state = INIT_STATE, actions) => {
    switch(actions.type){
        case REQUEST_GET_ALL_ADMIN_USERS:
            return{
                ...state,
                users: [],
                isLoading: true
            }
        case SUCCESS_GET_ALL_ADMIN_USERS:
            return{
                ...state,
                users: actions.payload,
                isLoading: false
            }
        case FAILED_GET_ALL_ADMIN_USERS:
            return{
                ...state,
                users: [],
                isLoading: false
            }

        case REQUEST_GET_ADMIN_USER:
            return{
                ...state,
                user: null,
                isLoading: true
            }
        case SUCCESS_GET_ADMIN_USER:
            return{
                ...state,
                user: actions.payload,
                isLoading: false
            }
        case FAILED_GET_ADMIN_USER:
            return{
                ...state,
                user: null,
                isLoading: false
            }


        case REQUEST_GET_USER_CAMPAIGNS:
            return{
                ...state,
                campaigns: []
            }
        case SUCCESS_GET_USER_CAMPAIGNS:
            return{
                ...state,
                campaigns: actions.payload
            }
        case FAILED_GET_USER_CAMPAIGNS:
            return{
                ...state,
                campaigns: []
            }


        case REQUEST_GET_DONATION_BY_CAMPAIGN:
            return{
                ...state,
                donations : []
            }
        case SUCCESS_GET_DONATION_BY_CAMPAIGN:
            return{
                ...state,
                donations : actions.payload
            }
        case FAILED_GET_DONATION_BY_CAMPAIGN:
            return{
                ...state,
                donations : []
            }


        case REQUEST_GET_ALL_WITHDRAWALS:
            return{
                ...state,
                withdrawls: []
            }
        case SUCCESS_GET_ALL_WITHDRAWALS:
            return{
                ...state,
                withdrawls: actions.payload
            }
        case FAILED_GET_ALL_WITHDRAWALS:
            return{
                ...state,
                withdrawls: []
            }


        case REQUEST_GET_WITHDRAWAL_BY_CAMPAIGN:
            return{
                ...state,
                withdrawls : []
            }
        case SUCCESS_GET_WITHDRAWAL_BY_CAMPAIGN:
            return{
                ...state,
                withdrawls : actions.payload
            }
        case FAILED_GET_WITHDRAWAL_BY_CAMPAIGN:
            return{
                ...state,
                withdrawls : []
            }
        default:
            return state;
    }
}