import axios from "axios";
import { getAuthData } from "../auth/auth";


export const getAsync = (url) =>{
    let {token} = getAuthData()
    let headers = getHeaders(token)
    const instance = axios.create()

    let response = instance.request({
        method:'get',
        url:url,
        headers: headers
    });
    return response;
}

export const postAsync = (url,d) =>{
    let {token} = getAuthData();
    let headers = getHeaders(token)

    const instance = axios.create()
    let response = instance.request({
        method:'POST',
        url:url,
        data:d,
        headers: headers
    });
    return response;




}

export const putAsync = (url,d) =>{
    let {token} = getAuthData();
    let headers = getHeaders(token)

    const instance = axios.create()
    let response = instance.request({
        method:'PUT',
        url:url,
        data:d,
        headers: headers
    });
    return response;

}

function getHeaders(token){
    let headers = {}
    if(token){
        headers = {
            "Content-type": "application/json; charset=UTF-8",
            "token": token
        };
    }

    return headers;
}