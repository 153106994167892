import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {Redirect, Switch} from 'react-router-dom'
import { REQUEST_LOGOUT } from '../../../settings/constants/constants'
import { setAuthData } from '../../../utils/auth/auth'

export function Logout() {
  const dispatch = useDispatch()
  // useEffect(() => {
  //   dispatch(auth.actions.logout())
  //   document.location.reload()
  // }, [dispatch])
  useEffect(() =>{
    dispatch({type: REQUEST_LOGOUT})
    setAuthData({})
  },[dispatch])

  return (
    <Switch>
      <Redirect to='/auth/login' />
    </Switch>
  )
}
