//redux

export const REQUEST_LOGIN = "REQUEST_LOGIN"
export const SUCCESS_LOGIN = "SUCCESS_LOGIN"
export const FAILED_LOGIN = "FAILED_LOGIN"

export const REQUEST_LOGOUT = "REQUEST_LOGOUT"

export const REQUEST_GET_ALL_ADMIN_USERS = "REQUEST_GET_ALL_ADMIN_USERS"
export const SUCCESS_GET_ALL_ADMIN_USERS = "SUCCESS_GET_ALL_ADMIN_USERS"
export const FAILED_GET_ALL_ADMIN_USERS = "FAILED_GET_ALL_ADMIN_USERS"

export const REQUEST_GET_ADMIN_USER = "REQUEST_GET_ADMIN_USER"
export const SUCCESS_GET_ADMIN_USER = "UCCESS_GET_ADMIN_USER"
export const FAILED_GET_ADMIN_USER = "FAILED_GET_ADMIN_USER"

export const REQUEST_GET_DONATION_BY_CAMPAIGN = "REQUEST_GET_DONATION_BY_CAMPAIGN"
export const SUCCESS_GET_DONATION_BY_CAMPAIGN = "SUCCESS_GET_DONATION_BY_CAMPAIGN"
export const FAILED_GET_DONATION_BY_CAMPAIGN = "FAILED_GET_DONATION_BY_CAMPAIGN"

export const REQUEST_GET_USER_CAMPAIGNS = "REQUEST_GET_USER_CAMPAIGNS"
export const SUCCESS_GET_USER_CAMPAIGNS = "SUCCESS_GET_USER_CAMPAIGNS"
export const FAILED_GET_USER_CAMPAIGNS = "FAILED_GET_USER_CAMPAIGNS"

export const REQUEST_GET_ALL_WITHDRAWALS = "REQUEST_GET_ALL_WITHDRAWALS"
export const SUCCESS_GET_ALL_WITHDRAWALS = "SUCCESS_GET_ALL_WITHDRAWALS"
export const FAILED_GET_ALL_WITHDRAWALS = "FAILED_GET_ALL_WITHDRAWALS"

export const REQUEST_GET_WITHDRAWAL_BY_CAMPAIGN = "REQUEST_GET_WITHDRAWAL_BY_CAMPAIGN"
export const SUCCESS_GET_WITHDRAWAL_BY_CAMPAIGN = "SUCCESS_GET_WITHDRAWAL_BY_CAMPAIGN"
export const FAILED_GET_WITHDRAWAL_BY_CAMPAIGN = "FAILED_GET_WITHDRAWAL_BY_CAMPAIGN"

//redux